<template>
  <div id="container" v-if="fetch_data">
    <section id="event">
      <div class="wrap">
        <div class="register_step flex">
          <div class="step_box flex on">
            <div class="num">1</div>
            <p>申し込み<br class="sp">フォーム</p>
          </div>
          <div class="step_box flex on">
            <div class="num">2</div>
            <p>申し込み内容の<br class="sp">確認</p>
          </div>
          <div class="step_box flex">
            <div class="num">3</div>
            <p>決済情報の<br class="sp">入力</p>
          </div>
          <div class="step_box flex">
            <div class="num">4</div>
            <p>参加申し込みを<br class="sp">受け付けました</p>
          </div>
        </div>
        <div class="cts_box">
          <h2 class="sub_ttl"><span>申し込み内容の確認</span></h2>
          <p>
            以下の内容で月例会参加の申し込みを行います。<br>
            参加者の氏名、住所等は月例会の参加に必要な教材の送付先として使用する場合がございます。異なる宛先への送付をご希望の方は、画面下「前の画面にもどる」ボタンより入力画面にもどって必要な情報の入力をお願いいたします。内容に変更のない方は、画面下の「決済情報を入力する」ボタンから決済内容の入力をお願いします。
          </p>
        </div>

        <div class="cts_box">
          <h3 class="min_ttl">申し込み内容</h3>

          <div class="df_table">
            <dl class="flex">
              <dt>おなまえ</dt>
              <dd>{{ current_user.last_name }} {{ current_user.first_name }}</dd>
            </dl>
            <dl class="flex">
              <dt>塾生番号</dt>
              <dd>{{ current_user.number }}</dd>
            </dl>
          </div>
        </div>

        <div class="cts_box workshop_select">
          <h3 class="min_ttl">月例会メニュー</h3>
          <p class="lead_txt">現在以下のメニューが選択されています。</p>

          <select v-if="model.required_social && model.social_price" v-model="user.join_social_party" disabled>
            <option v-bind:value="true" v-if="model.rank_price !== null" selected="">
              <template v-if="model.held_type === 'held_type_normal'">月例会</template>
              参加費 ＋ 懇親会：¥{{ delimited(model.rank_price + model.social_price) }}
            </option>
            <option v-bind:value="true" v-else selected="">
              <template v-if="model.held_type === 'held_type_normal'">月例会</template>
              懇親会：¥{{ delimited(model.social_price) }}
            </option>
          </select>

          <select v-else-if="model.rank_price !== null && model.social_price" v-model="user.join_social_party" disabled>
            <option v-bind:value="true" selected="">
              <template v-if="model.held_type === 'held_type_normal'">月例会</template>
              参加費 ＋ 懇親会：¥{{ delimited(model.rank_price + model.social_price) }}
            </option>
            <option v-bind:value="false">
              <template v-if="model.held_type === 'held_type_normal'">月例会</template>
              参加費：¥{{ delimited(model.rank_price) }}
            </option>
          </select>

          <select v-else-if="model.rank_price !== null && !model.social_price" v-model="user.join_social_party" disabled>
            <option v-bind:value="false" selected="">
              <template v-if="model.held_type === 'held_type_normal'">月例会</template>
              参加費：¥{{ delimited(model.rank_price) }}
            </option>
          </select>

          <select v-else v-model="user.join_social_party" disabled>
            <option v-bind:value="true" selected="">
              <template v-if="model.held_type === 'held_type_normal'">月例会</template>
              懇親会：¥{{ delimited(model.social_price) }}
            </option>
          </select>
        </div>

        <div class="cts_box">
          <h3 class="min_ttl">お会計内容</h3>
          <p class="lead_txt">参加を希望されるすべての月例会の合計額を表示しています。</p>
          <div class="register_box">
            <template v-if="model.rank_price !== null">
              <dl class="flex">
                <dt>{{ model.title }}</dt>
                <dd class="item">{{ current_user.rank_label }}</dd>
                <dd class="price">¥{{ delimited(model.rank_price) }}</dd>
              </dl>
              <dl class="flex" v-if="user.join_social_party">
                <dt></dt>
                <dd class="item">懇親会参加費</dd>
                <dd class="price">¥{{ delimited(model.social_price) }}</dd>
              </dl>
            </template>

            <template v-else>
              <dl class="flex">
                <dt>{{ model.title }}</dt>
                <dd class="item">懇親会参加費</dd>
                <dd class="price">¥{{ delimited(model.social_price) }}</dd>
              </dl>
            </template>

            <dl class="flex" v-if="user.use_coupon">
              <dt>クーポンのご利用</dt>
              <dd class="item"></dd>
              <dd class="price">¥-{{ delimited(discountCouponPrice()) }}<span v-if="false">受講額 ¥-{{ delimited(discountCouponPrice()) }}%オフ</span></dd>
            </dl>
            <dl class="flex" v-if="user.point">
              <dt>ポイントのご利用</dt>
              <dd class="item"></dd>
              <dd class="price">-{{ delimited(user.point) }}</dd>
            </dl>
            <dl class="flex total">
              <dt></dt>
              <dd class="item">お会計合計：</dd>
              <dd class="price">¥{{ delimited(user.total_price) }}</dd>
            </dl>
          </div>
        </div>

        <div class="cts_box" v-if="!model.rank_regular_plus_request_enabled && model.lottery_enabled">
          <!-- 抽選-->
          <div class="button_wrap">
            <p class="alnC mb20">
              内容に変更なければ<br class="sp">以下のボタンを押してください。（決済は抽選後に行われます）
            </p>
            <p class="alnC mb20">
              {{ model.lottery_lead_text }}
            </p>
            <button type="button" class="submit light arrow" @click="paymentClicked()">研修会参加の抽選に申し込む</button>
          </div>
        </div>

        <div class="cts_box" v-else-if="user.total_price > 0">
          <div class="button_wrap">
            <p class="alnC mb20">
              内容に変更なければ<br class="sp">続いて代金の決済を行います
            </p>
            <button type="button" class="submit light arrow" @click="paymentClicked()">決済情報を入力する</button>
            <div class="paygent flex">
              <img src="/common/images/paygent_logo.png" alt="PAYGENT">
              <p>
                クレジットカード決済サービス会社（PAYGENT）の決済画面に移動します。PAYGENTは、NTTデータと三菱UFJニコスの合弁による国内大手決済サービス会社です。
              </p>
            </div>
          </div>
        </div>

        <div class="cts_box" v-else>
          <div class="button_wrap">
            <p class="alnC mb20">
              内容に変更なければ<br class="sp">以下のボタンを押してください。（決済は不要です）
            </p>
            <button type="button" class="submit light arrow" @click="paymentClicked()">月例会に申し込む</button>
          </div>
        </div>

        <div class="cts_box">
          <div class="button_wrap">
            <p class="alnC mb20">
              入力内容を変更する場合は<br class="sp">こちらのボタンを押してください
            </p>
            <router-link :to="{ name: 'GetsureiForm', params: {id: model.id}, query: $route.query}"
                         class="submit light skeleton arrow back">
              前のページにもどる
            </router-link>
          </div>
        </div>

      </div>
    </section>
  </div>
</template>

<script>
import Libraries from '@/components/Libraries.vue'

export default {
  mixins: [Libraries],
  components: {},
  data() {
    return {
      id: this.$route.params.id,
      fetch_data: null,
      request_exec: false,
      model: null,
      user: null
    }
  },
  mounted() {
    // form token
    if (!this.checkFormToken('Getsurei')) return

    if (!this.$store.state.user) {
      this.$router.push({name: 'GetsureiForm', params: {id: this.$route.params.id}, query: this.$route.query})
    } else {
      this.user = this.$store.state.user
      this.fetchData()
    }
  },
  methods: {
    fetchData() {
      this.startAjax()
      this.axios
          .get(`${this.env.api_base_url}getsurei/page/${this.id}.json`, {
            params: {
              draft: this.$route.query.draft,
            }
          })
          .then(response => {
            this.fetch_data = response.data
            this.model = response.data.monthly_live
          })
          .catch((response) => {
            this.errorAjax(response)
          })
          .finally(() => {
            this.finishAjax()
          })
    },

    discountCouponPrice() {
      if (!this.user.use_coupon) {
        return 0
      } else {
        let total_price = this.model.rank_price
        if (this.model.required_social || this.user.join_social_party) {
          total_price += this.model.social_price
        }
        return Math.min(total_price, this.fetch_data.coupon.price)
      }
    },

    paymentClicked() {
      if (this.request_exec) return
      this.request_exec = true

      this.startUpload()
      this.axios
          .post(`${this.env.api_base_url}getsurei/paygent.json`, {
            monthly_live_user: this.user,
            draft: this.$route.query.draft
          })
          .then((response) => {
            //console.log(response)
            document.location.href = response.data.url
          })
          .catch((error) => {
            alert(error.response.data.error)
          })
          .finally(() => {
            this.finishUpload()
            this.request_exec = false
          })
    },
  }
}
</script>
